import React, {useState} from 'react';
import './App.css'
import logo from './assets/images/logo-trademarked.svg';
import LandingPage from './components/LandingPage';

function App() {
  const [lightmode, setLightmode] = useState(true);

  return (
    <>
        <LandingPage logo={logo} lightmode={lightmode} setLightmode={setLightmode}/>
    </>
  );
}

export default App;
